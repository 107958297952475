import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{attrs:{"value":_vm.isAddNewSchoolSidebarActive,"temporary":"","touchless":"","right":!_vm.$vuetify.rtl,"width":_vm.$vuetify.breakpoint.smAndUp ? 350 : '100%',"app":""},on:{"input":function (val) { return _vm.$emit('update:is-add-new-school-sidebar-active', val); }}},[_c(VCard,{attrs:{"height":"100%"}},[_c('div',{staticClass:"drawer-header d-flex align-center mb-4"},[_c('span',{staticClass:"font-weight-semibold text-base text--primary"},[_vm._v("Nieuwe leerkracht toevoegen")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('update:is-add-new-school-sidebar-active',false)}}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{staticClass:"mb-6",attrs:{"outlined":"","dense":"","rules":[_vm.validators.required],"label":"Naam","placeholder":"","hide-details":"auto"},model:{value:(_vm.schoolData.name),callback:function ($$v) {_vm.$set(_vm.schoolData, "name", $$v)},expression:"schoolData.name"}}),_c(VTextField,{staticClass:"mb-6",attrs:{"outlined":"","dense":"","rules":[_vm.validators.required],"label":"Adres","placeholder":"","hide-details":"auto"},model:{value:(_vm.schoolData.address),callback:function ($$v) {_vm.$set(_vm.schoolData, "address", $$v)},expression:"schoolData.address"}}),_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary","type":"submit"}},[_vm._v(" Toevoegen ")]),_c(VBtn,{attrs:{"color":"secondary","outlined":"","type":"reset"},on:{"click":_vm.resetschoolData}},[_vm._v(" Annuleren ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }