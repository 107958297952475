<template>
  <div id="school-list">
    <!-- app drawer -->
    <school-list-add-new
      v-model="isAddNewSchoolSidebarActive"
      :login-methods="loginMethods"
      @refetch-data="fetchSchools"
    ></school-list-add-new>

    <!-- school total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in schoolTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveSchoolTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveSchoolTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveSchoolTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveSchoolTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- table -->
    <v-card>
      <v-card-title>
        Scholen
      </v-card-title>
      <!-- Login method filter -->
      <!-- <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="loginMethodFilter"
            placeholder="Kies inlogmethode"
            :items="loginMethods"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col>
      </v-row> -->

      <!-- <v-divider class="mt-4"></v-divider> -->

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Zoeken"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewSchoolSidebarActive = !isAddNewSchoolSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>School toevoegen</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-4"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="schoolListTable"
        :options.sync="options"
        :server-items-length="totalSchoolListTable"
        :loading="loading"
        loading-text="Scholen aan het laden..."
        no-data-text="Geen scholen gevonden"
        show-select
      >
        <!-- Name -->
        <template #[`item.name`]="{item}">
          <router-link
            :to="{ name : 'administration-school-view', params : { id : item.id } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.name }}
          </router-link>
        </template>

        <!-- Address -->
        <template #[`item.address`]="{item}">
          <div class="text-truncate">
            {{ item.address }}
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'administration-school-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="onBtnDeleteSchoolClick(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <!-- Delete dialog -->
    <v-dialog
      v-if="deleteSchool.showDeleteDialog"
      v-model="deleteSchool.showDeleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          School verwijderen
        </v-card-title>

        <v-card-text>
          Ben je zeker dat je {{ deleteSchool.schoolToDelete.firstName }} {{ deleteSchool.schoolToDelete.lastName }} wil verwijderen? Deze actie kan niet ongedaan gemaakt worden. Ook alle gekoppelde inloggegevens zullen hiermee verwijderd worden.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            @click="deleteSchool.showDeleteDialog = false"
          >
            Annuleren
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="onBtnDeleteSchoolConfirm"
          >
            Verwijderen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiFileDocumentOutline, mdiPlus, mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import axios from '@axios'
import { avatarText } from '@core/utils/filter'
import schoolStoreModule from '../schoolStoreModule'
import SchoolListAddNew from './SchoolListAddNew.vue'

import useSchoolsList from './useSchoolsList'

export default {
  components: {
    SchoolListAddNew,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-school'
    const isDialogVisible = ref(false)

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, schoolStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const {
      schoolListTable,
      tableColumns,
      searchQuery,
      loginMethodFilter,
      planFilter,
      statusFilter,
      totalSchoolListTable,
      loading,
      options,
      schoolTotalLocal,
      selectedRows,

      fetchSchools,
      resolveSchoolRoleVariant,
      resolveSchoolRoleIcon,
      resolveSchoolStatusVariant,
      resolveSchoolTotalIcon,
    } = useSchoolsList()

    const isAddNewSchoolSidebarActive = ref(false)

    // Login methods
    const loginMethods = ref([])
    axios.get('/loginmethod').then(response => {
      loginMethods.value = response.data.loginMethods
    })

    // School verwijderen
    const deleteSchool = ref({
      showDeleteDialog: false,
      schoolToDelete: {},
    })

    function onBtnDeleteSchoolClick(school) {
      deleteSchool.value.showDeleteDialog = true
      deleteSchool.value.schoolToDelete = school
    }

    function onBtnDeleteSchoolConfirm() {
      console.log(deleteSchool.value.schoolToDelete.id)
      store.dispatch('app-school/deleteSchool', deleteSchool.value.schoolToDelete).then(() => {
        deleteSchool.value.showDeleteDialog = false
        fetchSchools()
      })
    }

    return {
      deleteSchool,
      onBtnDeleteSchoolClick,
      onBtnDeleteSchoolConfirm,
      isDialogVisible,
      schoolListTable,
      tableColumns,
      searchQuery,
      loginMethodFilter,
      planFilter,
      statusFilter,
      totalSchoolListTable,
      loginMethods,
      loading,
      options,
      schoolTotalLocal,
      isAddNewSchoolSidebarActive,
      selectedRows,
      avatarText,
      resolveSchoolRoleVariant,
      resolveSchoolRoleIcon,
      resolveSchoolStatusVariant,
      resolveSchoolTotalIcon,
      fetchSchools,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
