import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSchools(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/school', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSchool(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/school/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSchool(ctx, schoolData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/school', schoolData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSchool(ctx, schoolData) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/school/${schoolData.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSchool(ctx, schoolData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/school/${schoolData.id}`, schoolData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
