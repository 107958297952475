import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useSchoolsList() {
  const schoolListTable = ref([])

  const tableColumns = [
    { text: 'Naam', value: 'name' },
    { text: 'Adres', value: 'address' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalSchoolListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const schoolTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchSchools = () => {
    store
      .dispatch('app-school/fetchSchools', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(response => {
        const { schools } = response.data

        schoolListTable.value = schools
        totalSchoolListTable.value = schools.length
        schoolTotalLocal.value = []

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchSchools()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveSchoolRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'error'

    return 'primary'
  }

  const resolveSchoolRoleIcon = role => {
    if (role === 'subscriber') return mdiAccountOutline
    if (role === 'author') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveSchoolStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  const resolveSchoolTotalIcon = total => {
    if (total === 'Total Schools') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Schools') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Schools') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Schools') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    schoolListTable,
    tableColumns,
    searchQuery,
    totalSchoolListTable,
    loading,
    options,
    schoolTotalLocal,
    selectedRows,
    fetchSchools,
    resolveSchoolRoleVariant,
    resolveSchoolRoleIcon,
    resolveSchoolStatusVariant,
    resolveSchoolTotalIcon,
  }
}
