<template>
  <v-navigation-drawer
    :value="isAddNewSchoolSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-school-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Nieuwe leerkracht toevoegen</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-school-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <!-- name -->
          <v-text-field
            v-model="schoolData.name"
            outlined
            dense
            :rules="[validators.required]"
            label="Naam"
            placeholder=""
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <!-- Adres -->
          <v-text-field
            v-model="schoolData.address"
            outlined
            dense
            :rules="[validators.required]"
            label="Adres"
            placeholder=""
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Toevoegen
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetschoolData"
          >
            Annuleren
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'isAddNewSchoolSidebarActive',
    event: 'update:is-add-new-school-sidebar-active',
  },
  props: {
    isAddNewSchoolSidebarActive: {
      type: Boolean,
      required: true,
    },
    loginMethods: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankSchoolData = {
      name: '',
      address: '',
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const schoolData = ref(JSON.parse(JSON.stringify(blankSchoolData)))
    const resetschoolData = () => {
      schoolData.value = JSON.parse(JSON.stringify(blankSchoolData))
      resetForm()
      emit('update:is-add-new-school-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        // Opslaan & sluiten
        store.dispatch('app-school/addSchool', schoolData.value).then(() => {
          emit('refetch-data')
          emit('update:is-add-new-school-sidebar-active', false)
        })

        resetschoolData()
      } else {
        validate()
      }
    }

    return {
      resetschoolData,
      form,
      onSubmit,
      schoolData,
      valid,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
